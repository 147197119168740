import { isDefined } from "@clipboard-health/util-ts";
import { type LegacyShift } from "@lib/interface";
import { useGetWorkerActiveStreaks } from "@src/appV2/Streaks/api";
import { type Worker } from "@src/appV2/Worker/api/types";
import { isEmpty } from "lodash";

import { useStreaksEnabledForHcp, useStreaksEnabledGlobally } from "./api/featureFlagHooks";

interface StreaksSegmentData {
  shouldEmitSegmentEvent: boolean;
  payBoostPercentage?: number;
  msa: string;
  streaksBonus: number;
}

function calculateStreakBonusForInstantPay(
  pay: number,
  time: number,
  payBoostPercentage: number
): number {
  const preRewardRate = pay / (payBoostPercentage + 1);
  return preRewardRate * payBoostPercentage * time;
}

function calculateStreaksBonus(
  shift: LegacyShift | undefined,
  payBoostPercentage: number | undefined
) {
  if (
    !isDefined(shift) ||
    !isDefined(shift.pay) ||
    !isDefined(shift.time) ||
    !isDefined(payBoostPercentage)
  ) {
    return 0;
  }

  const { pay, time } = shift;
  return calculateStreakBonusForInstantPay(pay, time, payBoostPercentage);
}

export function useGetStreaksSegmentData(
  workerId: string | undefined,
  worker?: Worker,
  shift?: LegacyShift
): StreaksSegmentData {
  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();
  const isStreaksOnForHcp = useStreaksEnabledForHcp();

  const { data: streaksResponse } = useGetWorkerActiveStreaks(
    { filter: { date: new Date() }, agentId: workerId ?? "" },
    { enabled: !isEmpty(workerId) && isStreaksEnabledGlobally }
  );

  const payBoostPercentage = streaksResponse?.data?.[0]?.attributes?.payBoostPercentage;
  const streaksBonus = calculateStreaksBonus(shift, payBoostPercentage);

  return {
    shouldEmitSegmentEvent: isStreaksEnabledGlobally && isStreaksOnForHcp,
    payBoostPercentage: streaksResponse?.data?.[0]?.attributes?.payBoostPercentage,
    msa: worker?.address?.metropolitanStatisticalArea ?? "",
    streaksBonus,
  };
}
